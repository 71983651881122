.loader {
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.9;
}
