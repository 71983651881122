.login-page {
  background-color: $ps-bg-gray;
  padding-bottom: 40px;

  &_headline {
    margin-bottom: 20px;
  }

  &_content {
    padding: 30px 20px 50px;
    background-color: $ps-bg-white;
    text-align: center;
    color: $ps-font-black;

    .btn_submit, .btn_line {
      margin: 0 auto;
      max-width: 90px;
      margin-top: 20px;
    }

    .btn_line {
      max-width: 185px;
    }
    .loading-spinner-button{
      margin-top: 30px;
    }
  }

  &_inputs {
    &_label {
      color: #95999B;
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 44px;
      text-align: left;
    }

    &_input {
      width: 100%;
      padding: 7.5px;
      border: solid 1px $ps-bc-gray;
    }
  }


  &_protection-plan {
    margin-top: 60px;

    .btn {
      max-width: 230px;
      margin: 0 auto;
    }

    &_subheadline {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .login-page {
    padding-bottom: 110px;

    &_headline {
      margin-bottom: 60px;
    }

    &_inputs{
      max-width: 630px;
      margin: 0 auto;
    }

    &_content {
      padding: 50px 0 100px;
      background-color: $ps-bg-white;
      text-align: center;
      color: $ps-font-black;

      .btn_submit, .btn_line {
        max-width: 120px;
      }

      .btn_line {
        max-width: 210px;
      }
      .loading-spinner-button{
        margin-top: 0;
      }
    }

    &_inputs {
      &_label {
        margin: 0 auto;
      }

      &_input {
        width: 100%;
        padding: 15px;
        border: solid 1px $ps-bc-gray;
      }

      margin-bottom: 30px;
    }
  }
}
