.sidenav {
    flex: 0 0 $ps-sidenav-width;
    width: $ps-sidenav-width;
    padding: 40px 20px 80px 0;
    margin-right: 30px;

    &_nav {
        list-style: none;
        padding-left: 0;

        &_item {
            padding: 10px 0;
        }

        &_link {
            display: block;
            color: $ps-font-gray;
            margin-bottom: 0;

            &:hover {
                color: $ps-font-black;
            }

            &--actice {
                color: $ps-font-black;
            }
        }
    }

    &_.sidenav_container {

    }

    &--sticky {
        .sidenav_container {
            position: sticky;
        }
    }
}

  
//.sidenav a {
//    padding: 15px 8px 0px 5px;
//    text-decoration: none;
//    font-size: 18px;
//    color: #95999b;
//    display: block;
//    margin-bottom: 0px;
//}
//
//.sidenav a:hover {
//    color: #000000;
//}
//
//.sidenav-main {
//    margin-left: 0px;
//    font-size: none;
//    padding: none;
//}
//
//
//@media only screen and (min-width: $tablet) {
//    .sidenav {
//        padding-top: 0px;
//    }
//    .sidenav a {
//        font-size: .75rem;
//    }
//}
