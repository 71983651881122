.plusicon {
  background-size: 48px;
  height: 48px;
  width: 48px;

  &--blue{
    background-image: url("/static/image/icons/plus/blue_plus.png");
  }
  &--black{

  }
  &--white{
    background-image: url("/static/image/icons/plus/white_plus.png");
  }
}


@media only screen and (min-width: $tablet) {
  .plusicon {
    background-size: 80px;
    height: 80px;
    width: 80px;
  }
}
