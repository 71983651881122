.landingpage {
  background-color: $ps-bg-light-gray;
  .hint{
    color: black;
    padding: 0px 10px 0 10px;
    margin-top: 20px;
  }


  .center-box {
    padding-top: 20px;
    width: 350px;
    height: 500px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    background-color: $ps-bg-light-gray;
    position: absolute;

    h1 {
      text-align: center;
      margin-bottom: 30px;
    }

    .localization-wrapper {
      min-width: 200px;
      width: 100%;
      margin: 0 auto;
      position: absolute;
    }

    .localization-picker {
      background-color: $ps-bg-light-gray;
      &-element {
        cursor: pointer;
      }
      ul {
        padding: 20px 0 20px 0;
      }

      li {
        margin: 10px 20px 10px 20px;
        display: flex;

        img {
          width: 42px;
          height: auto;
          margin: 10px;
          float: left;
        }

        p.country-name {
          color: black;
          font-family: Arial, sans-serif;
          margin: 10px 10px 10px 20px;
        }
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }


  @media only screen and (max-width: $tablet) {
    .center-box {
      height: 300px;
      position: absolute;
      left: 50%;
      top: 27%;

      li {
        margin: 2px;
      }
    }
  }
}


