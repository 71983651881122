.discount {
  margin-bottom: 24px;
  display: flex;
  gap: 10px;
  flex-direction: column;

  .form-discount-input-group {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  h3.name {
    margin-right: 100px;
    vertical-align: middle;
    margin-top: 20px;
    font-size: 30px;
    line-height: 30px;
  }

  .btn-primary {
    vertical-align: middle;
  }

  span{
    border: 0;
    font-weight: bold;
    font-size: 20px;
    padding-top: 25px;
  }

  .message {
    font-size: 18px;
  }
}