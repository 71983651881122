.form {
  &-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    color: $ps-font-black;
  }

  &-section {
    padding: 18px 25px 25px;
    background-color: $ps-bg-white;
    color: $ps-font-black;
    //margin: 0 15px 14px;

    @include media-breakpoint-up(xl) {
      padding: 50px 50px 76px;
      margin: 0 0 145px;
    }
  }

  &-fieldset {
    margin-bottom: 10px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 30px;
    }
  }

  &-headline {
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 17px;
    font-weight: bold;
    color: $ps-font-gray;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
      font-size: 32px;
      line-height: 37px;
    }
  }

  &-text {
    font-size: 16px;
    line-height: 1.2;

    @include media-breakpoint-up(xl) {
      font-size: 20px;
      line-height: 1.5;
    }
  }

  &-info-box {
    padding: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 3);

    border: 1px solid $ps-bc-black;
    border-radius: 5px;

    @include media-breakpoint-up(xl) {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: map-get($spacers, 5);
    }


    &_headline {
      font-size: 15px;
      line-height: 17px;

      @include media-breakpoint-up(xl) {
        font-size: 24px;
        line-height: 1.5;
      }
    }
  }
}



//
// Textual form controls
//

.form-control {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: 10px;
  line-height: 11px;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: 0;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
    line-height: $input-line-height;
    height: $input-lg-height;
    padding: $input-btn-padding-lg-y $input-btn-padding-lg-x;
  }

  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

select.form-control {
  &:focus::-ms-value {
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}


//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: calc(#{$input-padding-y} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
  margin-bottom: 0; // Override the `<label>/<legend>` default
  font-size: inherit; // Override the `<legend>` default
  line-height: $input-line-height;
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;
}


// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// Repeated in `_input_group.scss` to avoid Sass extend issues.


// stylelint-disable no-duplicate-selectors
select.form-control {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.form-control {
  height: auto;
}
// stylelint-enable no-duplicate-selectors

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
  margin-bottom: $form-group-margin-bottom;

  @include media-breakpoint-up(lg) {
    margin-bottom: $form-group-lg-margin-bottom;
  }

  label, legend {

  }

  .hover-img-link {
    color: $ps-font-light-gray;
    text-decoration: underline;
    float: right;
    line-height: 15px;

    button {
      background-color: transparent;
      border: none;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      text-decoration: underline;
      color: $ps-font-light-gray;
    }
  }

  .popup {
    &-outer {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: rgba(0,0,0, 0.5);
    }

    &-inner {
      position: absolute;
      left: 25%;
      right: 25%;
      top: 25%;
      bottom: 25%;
      margin: auto;
      background: white;
    }
  }

  .error-text {
    margin: 5px 0 0 1px;
    color: red;
  }
}
.form-group-price{
  width: 920px;
}
.form-group-price-large{
  width: 990px;
}


.form-label {
  display: inline-block;
  font-size: 10px;
  line-height: 11px;
  font-weight: bold;
  color: $ps-font-gray;
  text-transform: uppercase;
  margin-bottom: 10px;

  span{
    font-size: 10px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
    line-height: 23px;
  }

}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}


// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$form-row-gap;
  margin-left: -$form-row-gap;

  @include media-breakpoint-up(lg) {
    margin-right: -$form-row-lg-gap;
    margin-left: -$form-row-lg-gap;
  }

  > .col,
  > [class*="col-"] {
    padding-right: $form-row-gap;
    padding-left: $form-row-gap;
  }

  @include media-breakpoint-up(lg) {
    > .col,
    > [class*="col-"] {
      padding-right: $form-row-lg-gap;
      padding-left: $form-row-lg-gap;
    }
  }
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
  position: relative;
  display: block;
  padding-left: $form-check-input-gutter;
}

.form-check-input {
  position: absolute;
  margin-top: 0.2rem;
  margin-left: -$form-check-input-gutter;

  @include media-breakpoint-up(xl) {
    margin-top: $form-check-input-margin-y;
  }

  &:disabled ~ .form-check-label {
    color: $ps-bg-light-gray;
  }

  &:checked ~ .form-check-label {
    font-weight: bold;
  }
  &:checked ~ .form-check-agreement-period-label {
    font-weight: normal;
  }
}

.form-check-label {
  font-size: 16px;
  line-height: 1.2;
  color: $ps-font-gray;
  margin-bottom: 0; // Override default `<label>` bottom margin

  @include media-breakpoint-up(xl) {
    font-size: 20px;
  }
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
  }
}

.form-check-box {
  display: inline-block;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
  }
}

.form-check-payment-period-label{
  color: $ps-bc-black;
  margin: 0 5px 0 5px;
}

.form-check-agreement-period-label{
  color: $ps-bc-black;
  font-size: 18px;
  margin: 0 5px 0 5px;
  a {
    text-decoration: underline;
  }
}

.form-check-span {
  margin-left: 7%;
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);
