.btn {
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  background-color: $ps-bg-blue;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  color: $ps-font-white;

  &_green {
    background-color: $ps-btn-cookie-green;

    &:hover {
      background-color: $ps-btn-cookie-black;
    }
  }

  &_submit {
    background-color: $ps-bg-black;
    color: $ps-font-white;
  }

  &_line {
    background-color: transparent;
    border-bottom: 1px solid $ps-bc-black;
    border-radius: 0;
    color: $ps-font-black;
    padding: 0;
    text-transform: uppercase;
  }

  &_line_gray {
    text-transform: uppercase;
    background-color: transparent;
    color: $ps-font-gray !important;
    border-radius: 0;
    padding: 0;
    border-bottom: 1px solid $ps-bc-gray;
  }

  &_transparent {
    background-color: transparent;
    border-color: $ps-bc-black;
    border: solid 1px $ps-bc-black;
    color: $ps-font-black;
  }

  &-primary, &-secondary, &-quarternary, &-link {
    display: inline-block;
    border: 1px solid $ps-bg-black;
    border-radius: 5px;
    padding: 11px 16px 10px;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;

    &.disabled,
    &:disabled {
      opacity: 0.7;
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    @include media-breakpoint-up(sm) {
      padding: 17px 24px 16px;
      font-size: 16px;
      line-height: 18px;
    }

    @include media-breakpoint-up(xl) {
      min-width: 220px;
    }
  }

  &-primary {
    background-color: $ps-bg-black;
    color: $ps-font-white;
    &--white{
      background-color: $ps-bg-white;
      color: $ps-font-gray;
    }
  }

  &-secondary {
    background-color: transparent;
    color: $ps-font-black;
    cursor: pointer;
    text-transform: uppercase;

    &--link {
      &::after {
        content: url("/static/image/home/quotes/linkicon.png");
        margin-left: 10px;
      }
    }
  }

  &-tertiary {
    display: inline-block;
    padding: 4px 25px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    font-weight: bold;
    background-color: transparent;
    border-bottom: 1px solid $ps-bg-black;
    border-radius: 0;
    color: $ps-font-tertiary;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 18px;
    }

    @include media-breakpoint-up(sm) {
      &--align-left {
        padding-left: 0;
        padding-right: 50px;
      }

      &--align-right {
        padding-left: 50px;
        padding-right: 0;
      }
    }
  }

  &-quarternary {
    background-color: $ps-bg-quarternary;
    color: $ps-font-white;
  }

  &-alt-link {
    color: $ps-bg-quarternary;
    text-decoration: underline;
  }
}

.collapse-btn {
  padding: 0;
  color: $ps-font-black;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
    line-height: 18px;
  }

  &::after {
    content: url("/static/image/icons/arrow-right.svg");
    margin-left: 5px;

    @include media-breakpoint-up(sm) {
      margin-left: 10px;
    }
  }
}


.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 300px;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    max-width: unset;
  }

  > * {
    width: 100%;
    margin-bottom: map-get($spacers, 2);

    @include media-breakpoint-up(md) {
      width: auto;
      margin-bottom: 0;
      margin-right: map-get($spacers, 2);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.loading-spinner-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &-loading-spinner {
    position: absolute;
    left: 45%;
    & + *{
      opacity: .5;
    }
  }

  &-text {
    align-self: center;
  }
}
