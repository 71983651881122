.cancel {
  padding-bottom: 80px;

  &_title{
    color: $ps-font-black;
    font-weight: bold;
    font-size: 34px;
  }

  &_headline{
    font-size: 34px;
  }

  &_headline, &_subheadline{
    font-weight: bold;
  }

  &_wrapper {
    background-color: $ps_bg-white;
    color: $ps-font-black;
    padding: 40px;
    width: 75%;
    margin: 0 auto;
  }

  &_container {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 40px;
  }

  &_menu {
    width: 50%;
    margin: 0 auto;
  }

  .downgradeOptions {
    padding: 0 0 20px 0;
    text-align: left;

    *:not(:last-child) {
      margin-top: 0.2rem;
      margin-bottom: 15px;
    }
  }

  &_btn {
    width: 300px;
    margin: 0 auto;
  }

  .form-check-label {
    font-size: 18px;
  }
}

@media only screen and (min-width: $tablet) {
  .cancel {
    &_title{
      padding-left: $mobile-cancel-left-padding;
    }

    &_headline {
      line-height: $mobile-headline-line-height;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .cancel {
    &_menu {
      width: 100%;
    }

    &_title{
      padding-left: $mobile-cancel-left-padding;
    }

    &_headline {
      line-height: $mobile-headline-line-height;
    }
  }
}
