.modal {
  position: fixed;
  inset: 0px;
  background: rgba(221, 221, 221, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 40px 20px 20px;

  &-dialog {
    width: 80%;
    max-width: 680px;
    max-height: 90%;
    overflow: auto;
  }

  &-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px;
  }

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 10px;

    button {
      font-size: 28px;
      margin-inline-start: auto;
      border: none;
      outline: none;
      background: transparent;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: bold;
  }

  &-body {
    color: #2c3437;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  &-error {
    font-size: 16px;
    line-height: 20px;
    color: #DF6134;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    * {
      margin: 0;
    }
  }
}
