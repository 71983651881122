// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.input-group {
  position: relative;
  display: flex;
  gap: 10px;
  flex-wrap: wrap; // For form validation feedback
  align-items: stretch;
  width: 100%;

  > .form-control,
  > .custom-select,
  > .custom-file {
    position: relative; // For focus state's z-index
    flex: 1 1 auto;
    // Add width 1% and flex-basis auto to ensure that button will not wrap out
    // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
    width: 1%;
    margin-bottom: 0;

    + .form-control,
    + .custom-select,
    + .custom-file {
      margin-left: -$input-border-width;
    }
  }

  // Bring the "active" form control to the top of surrounding elements
  > .form-control:focus,
  > .custom-select:focus,
  > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }

  // Bring the custom file input above the label
  > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
}


// Prepend and append
//
// While it requires one extra layer of HTML for each, dedicated prepend and
// append elements allow us to 1) be less clever, 2) simplify our selectors, and
// 3) support HTML5 form validation.

.input-group-prepend,
.input-group-append {
  display: flex;

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .btn {
    position: relative;
    z-index: 2;
  }

  .btn + .btn,
  .btn + .input-group-text,
  .input-group-text + .input-group-text,
  .input-group-text + .btn {
    margin-left: -$input-border-width;
  }
}

.input-group-prepend { margin-right: -$input-border-width; }
.input-group-append { margin-left: -$input-border-width; }


// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.input-group-text {
  display: flex;
  align-items: center;
  padding: $input-padding-y $input-padding-x;
  margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
  font-size: $font-size-base; // Match inputs
  font-weight: bold;
  line-height: $input-line-height;
  color: $input-group-addon-color;
  text-align: center;
  white-space: nowrap;
  background-color: $input-group-addon-bg;
  border: $input-border-width solid $input-group-addon-border-color;

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type="radio"],
  input[type="checkbox"] {
    margin-top: 0;
  }

  &--icon {
    img {
      width: auto;
      height: 18px;

      @include media-breakpoint-up(lg) {
        height: 30px;
      }
    }
  }
}


