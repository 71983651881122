.success {
  background-color: $ps-bg-light-gray;
  padding: 40px 0;

  &_title{
    color: $ps-font-black;
    font-weight: bold;
    font-size: 34px;
  }

  &_icon{
    height: 150px;
    width: 150px;
    background-image: url("/static/image/success/success.png");
    background-size: 150px;
    background-repeat: no-repeat;
    margin: 0 auto 30px;
  }

  &_headline{
    font-size: 34px;
  }

  &_headline, &_subheadline{
    font-weight: bold;
  }

  &_subheadline{
    margin-bottom: 10px;
  }

  &_wrapper {
    background-color: $ps_bg-white;
    color: $ps-font-black;
    padding: 40px;
  }

  &_container {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 40px;
  }

  &_btn {
    width: 300px;
    margin: 0 auto;
  }

  &_loading_spinner {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    opacity: 0.9;
    z-index: 101;
  }
}

@media only screen and (min-width: $tablet) {
  .success {
    &_wrapper {
      padding-bottom: 100px;
    }

    &_title{
      padding-left: $mobile-left-padding;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .success {
    &_title{
      padding-left: $mobile-left-padding;
    }

    &_headline {
      line-height: $mobile-headline-line-height;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .success {
    &_title{
      padding-left: 0;
    }

    &_headline {
      line-height: $mobile-headline-line-height;
    }
  }
}
