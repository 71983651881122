.simple_checkbox {
  margin-top: 30px;

  &_image{
    margin-top: 20px;
    width: 300px;
  }

  &_hint {
    float: right;
    margin: 0;
    font-size: 16px;
    color: $ps-font-gray;
    font-weight: bold;

    &_btn {
      cursor: help;
    }

    &:hover .overlay {
      opacity: 1;
      visibility: visible;
    }

    .overlay {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transition: .2s ease;
      color: $ps-font-black;
      background-color: $ps-bg-white;
      padding: 10px;
    }
  }

  .radio_buttons {
    display: inline-block;
    width: 150px;
    margin: 0;
  }
}