.repair-orders {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .repair-order {
    flex-direction: column;
    display: flex;
    background-color: white;

    &:empty {
      padding: 0;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    .text-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      line-height: 20px;
      font-size: 16px;
    }

    .btn-container {
      display: flex;
      justify-content: flex-start;
    }

    &:not(:last-child) {
      border-bottom: 1px solid black;
      padding-bottom: 30px;
    }
  }
}